import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Grid,
  Slider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl,
} from "@mui/material";

import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import { FaBinoculars } from "react-icons/fa";
import FastfoodIcon from '@mui/icons-material/Fastfood';
import { FaTaxi } from "react-icons/fa6";
import { FaPassport } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const packages = [
  {
    "_id": "65c5f21af6dbe8605cefe7be",
    "package_name": "test",
    "base_price": 100000,
    "hotel_type": 1,
    "city_id": 115936,
    "flight": false,
    "meal": true,
    "sight_seeing": true,
    "taxi": true,
    "visa": true,
    "cover_image": ""
  },
  {
    "_id": "65c5f239f6dbe8605cefe7c0",
    "package_name": "test1",
    "base_price": 119998,
    "hotel_type": 2,
    "city_id": 115936,
    "flight": true,
    "meal": false,
    "sight_seeing": true,
    "taxi": true,
    "visa": false,
    "cover_image": ""
  },
  {
    "_id": "65c5f261f6dbe8605cefe7c2",
    "package_name": "test2",
    "base_price": 199997,
    "hotel_type": 3,
    "city_id": 115936,
    "flight": true,
    "meal": true,
    "sight_seeing": true,
    "taxi": true,
    "visa": true,
    "cover_image": ""
  },
  {
    "_id": "65c5f27bf6dbe8605cefe7c4",
    "package_name": "test3",
    "base_price": 150000,
    "hotel_type": 1,
    "city_id": 115936,
    "flight": true,
    "meal": false,
    "sight_seeing": true,
    "taxi": true,
    "visa": true,
    "cover_image": ""
  },
  {
    "_id": "65c5f295f6dbe8605cefe7c6",
    "package_name": "test4",
    "base_price": 130000,
    "hotel_type": 2,
    "city_id": 115936,
    "flight": false,
    "meal": true,
    "sight_seeing": true,
    "taxi": false,
    "visa": true,
    "cover_image": ""
  },
  {
    "_id": "65c5f2aff6dbe8605cefe7c8",
    "package_name": "test5",
    "base_price": 180000,
    "hotel_type": 3,
    "city_id": 115936,
    "flight": true,
    "meal": true,
    "sight_seeing": false,
    "taxi": true,
    "visa": false,
    "cover_image": ""
  },
  {
    "_id": "65c5f2c9f6dbe8605cefe7ca",
    "package_name": "test6",
    "base_price": 210000,
    "hotel_type": 1,
    "city_id": 115936,
    "flight": false,
    "meal": true,
    "sight_seeing": true,
    "taxi": true,
    "visa": true,
    "cover_image": ""
  },
  {
    "_id": "65c5f2e3f6dbe8605cefe7cc",
    "package_name": "test7",
    "base_price": 140000,
    "hotel_type": 2,
    "city_id": 115936,
    "flight": true,
    "meal": true,
    "sight_seeing": true,
    "taxi": false,
    "visa": true,
    "cover_image": ""
  }
];

const PackageListing = () => {
  const [priceRange, setPriceRange] = useState([100, 5000]);
  const [starRatings, setStarRatings] = useState({
    threeStar: false,
    fiveStar: false,
    sevenStar: false,
  });
  const [mealOption, setMealOption] = useState("Breakfast");
  const [flightIncluded, setFlightIncluded] = useState("Included");

  const handlePriceRangeChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  const handleRatingChange = (event) => {
    setStarRatings({
      ...starRatings,
      [event.target.name]: event.target.checked,
    });
  };

  const handleMealOptionChange = (event) => {
    setMealOption(event.target.value);
  };

  const handleFlightIncludedChange = (value) => {
    setFlightIncluded(value);
  };

  const formatPrice = (price) => {
    return '₹'+ price.toLocaleString('en-IN', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const renderStars = (hotelType) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(i <= hotelType ? <StarIcon key={i} style={{ color: '#FFD700' }} /> : <StarBorderIcon key={i} style={{ color: '#FFD700' }} />);
    }
    return stars;
  };
  return (
    <Box sx={{ position: "relative", height: "100vh" }}>
      <Box
        sx={{
          backgroundImage: 'url("https://hldak.mmtcdn.com/prod-s3-hld-hpcmsadmin/holidays/images/cities/2588/9.jpg")',
          backgroundSize: "cover",
          height: "400px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          zIndex: 1,
        }}
      >
        <Typography
          sx={{
            fontFamily: "sans-serif",
            fontSize: "50px",
            fontWeight: 800,
            position: "sticky",
            top: "20px",
            zIndex: 1,
            color: "#fff",
          }}
        >
          Dubai Holiday Packages
        </Typography>
      </Box>
      <Box component={"div"} sx={{ position: "absolute", zIndex: 2, top: "150px" }}>
        <Box
          component={"div"}
          sx={{
            display: "flex",
            width: "80%",
            margin: "auto",
            borderRadius: "30px",
            position: "sticky",
            top: "80px",
            height: "calc(100vh - 80px)",
            overflowY: "auto",
            backgroundColor: "white",
            zIndex: 2,
            borderRight: 0,
            boxShadow: 10,
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Box
            sx={{
              width: "30%",
              borderRight: "2px solid #E5E5DF",
              borderRadius: "30px",
              position: "sticky",
              top: "0px",
              height: "calc(100vh - 80px)",
              overflowY: "auto",
              backgroundColor: "white",
              zIndex: 2,
            }}
          >
            <Typography
              sx={{
                paddingTop: "10px",
                paddingBottom: "10px",
                fontFamily: "monospace",
                color: "black",
                fontSize: "20px",
                fontWeight: 700,
                borderBottom: "1px solid #E5E5DF",
                borderRadius: "20px",
              }}
            >
              Filters
            </Typography>
            <Typography gutterBottom sx={{ paddingTop: "10px" }}>
              Price Range
            </Typography>
            <Slider
              value={priceRange}
              onChange={handlePriceRangeChange}
              valueLabelDisplay="auto"
              min={0}
              max={10000}
              step={100}
              marks={[
                { value: 0, label: "$0" },
                { value: 10000, label: "$10000" },
              ]}
              sx={{ width: "80%", marginBottom: "20px" }}
            />
            <Typography sx={{ borderBottom: "1px solid #E5E5DF", borderRadius: "20px", paddingBottom: "20px" }}>
              Selected range: ${priceRange[0]} - ${priceRange[1]}
            </Typography>

            <Typography variant="h6" gutterBottom sx={{ paddingTop: "20px" }}>
              Rating
            </Typography>
            <FormGroup
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingBottom: "10px",
                borderBottom: "1px solid #E5E5DF",
                borderRadius: "20px",
              }}
            >
              <FormControlLabel
                control={<Checkbox checked={starRatings.threeStar} onChange={handleRatingChange} name="threeStar" />}
                label="3 Star"
              />
              <FormControlLabel
                control={<Checkbox checked={starRatings.fiveStar} onChange={handleRatingChange} name="fiveStar" />}
                label="5 Star"
              />
              <FormControlLabel
                control={<Checkbox checked={starRatings.sevenStar} onChange={handleRatingChange} name="sevenStar" />}
                label="7 Star"
              />
            </FormGroup>
            <Typography variant="h6" gutterBottom sx={{ marginTop: "20px" }}>
              Meal Options
            </Typography>
            <FormControl
              component="fieldset"
              sx={{ borderBottom: "1px solid #E5E5DF", borderRadius: "20px", paddingBottom: "20px", width: "100%" }}
            >
              <RadioGroup
                aria-label="meal"
                name="meal"
                value={mealOption}
                onChange={handleMealOptionChange}
                sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
              >
                <FormControlLabel value="Breakfast" control={<Radio />} label="Breakfast" />
                <FormControlLabel value="Half Board" control={<Radio />} label="Half Board" />
                <FormControlLabel value="Full Board" control={<Radio />} label="Full Board" />
              </RadioGroup>
            </FormControl>
            <Typography variant="h6" gutterBottom sx={{ marginTop: "20px" }}>
              Flight Included
            </Typography>
            <FormGroup
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingBottom: "10px",
                borderBottom: "1px solid #E5E5DF",
                borderRadius: "20px",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={flightIncluded === "Included"}
                    onChange={() => handleFlightIncludedChange("Included")}
                  />
                }
                label="Included"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={flightIncluded === "Not Included"}
                    onChange={() => handleFlightIncludedChange("Not Included")}
                  />
                }
                label="Not Included"
              />
            </FormGroup>
          </Box>
          <Box sx={{ width: "70%", height: "80vh" }}>
          <Grid container spacing={3} sx={{ padding: '10px' }}>
              {packages.map(pkg => (
                <Grid item xs={6} key={pkg._id}>
                  <Link to="/tourDetails">
                  <Card sx={{ marginBottom: '20px', borderRadius: "30px", height: "fit-content" }}>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        height="140"
                        image={pkg.cover_image || "https://hldak.mmtcdn.com/prod-s3-hld-hpcmsadmin/holidays/images/cities/1940/nairobi-road.jpg"}
                        alt={pkg.package_name}
                        sx={{ height: "200px" }}
                      />
                      <CardContent sx={{ padding: '16px', backgroundColor: '#f9f9f9', borderRadius: "0 0 30px 30px" }}>
                        <Typography gutterBottom variant="h4" component="div" sx={{ fontWeight: 'bold', borderBottom :'1px solid #E5E5DF' }}>
                          {pkg.package_name}
                        </Typography>
                        <Box sx={{display: 'flex', flexWrap: 'wrap', width: '90%', justifyContent: 'space-between',  borderBottom :'1px solid #E5E5DF'}}>
                          {pkg.flight ?
                          <Box sx={{display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '50%', marginBottom: '15px', fontSize: '20px', color: 'white'}}>
                            <FlightTakeoffIcon sx={{border: "1px solid grey", borderRadius: "50px", backgroundColor: "grey", padding: "5px"}}/>
                          </Box>:
                          <Box sx={{display: 'none'}}></Box>}
                          {pkg.sight_seeing ?
                          <Box sx={{display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '50%', marginBottom: '15px', fontSize: '20px', color: 'white'}}>
                            <FaBinoculars style={{border: "1px solid grey", borderRadius: "50px", backgroundColor: "grey", padding: "8px"}}/>
                          </Box>:
                          <Box sx={{display: 'none'}}></Box>}
                          {pkg.meal ?
                          <Box sx={{display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '50%', marginBottom: '15px', fontSize: '20px', color: 'white'}}>
                            <FastfoodIcon sx={{border: "1px solid grey", borderRadius: "50px", backgroundColor: "grey", padding: "5px"}}/>
                          </Box>:
                          <Box sx={{display: 'none'}}></Box>}
                          {pkg.taxi ?
                          <Box sx={{display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '50%', marginBottom: '15px', fontSize: '20px', color: 'white'}}>
                            <FaTaxi style={{border: "1px solid grey", borderRadius: "50px", backgroundColor: "grey", padding: "8px"}}/>
                          </Box>:
                          <Box sx={{display: 'none'}}></Box>}
                          {pkg.visa ?
                          <Box sx={{display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '50%', marginBottom: '15px', fontSize: '20px', color: 'white'}}>
                            <FaPassport style={{border: "1px solid grey", borderRadius: "50px", backgroundColor: "grey", padding: "8px"}}/>
                          </Box>:
                          <Box sx={{display: 'none'}}></Box>}
                        </Box>
                        <Typography variant="body2" color="text.secondary" sx={{ marginBottom: '8px' }}>
                          Price starting from:   <span style={{fontSize: '30px', color: 'black', fontWeight: 600, fontFamily: 'sans-serif'}}>{formatPrice(pkg.base_price)}</span>
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ marginBottom: '8px' }}>
                          Hotel Rating: {renderStars(pkg.hotel_type)}
                        </Typography>
                      
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PackageListing;
