import React from 'react';
import styles from '../components/Navbar.module.css';
import { Button } from '@mui/material';

const Navbar = () => {
  return (
    <div className={styles.navbar}>
      <div className={styles.logo}>Induldge</div>
      <Button className={styles.loginButton} variant='contained'>Login</Button>
    </div>
  );
}

export default Navbar;
