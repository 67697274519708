import React from 'react';
import styles from '../components/TourSummary.module.css';

const TourSummary = () => {
  return (
    <div className={styles.summaryContainer}>
      <div className={styles.itinerary}>
        <p>
          <span role="img" aria-label="plane">✈️</span>
          Arrival in Abu Dhabi by Multiple Airlines Flight AI-173, UA-205 | Includes Check In Baggage
        </p>
        <div className={styles.location}>
          <h3>Abu Dhabi - 1 Night Stay</h3>
          <div className={styles.day}>
            <span>Day 1</span>
            <p>Check in to Sandman Hotel & Suites Abu Dhabi South, 3 Star</p>
          </div>
          <div className={styles.day}>
            <span>Day 2</span>
            <p>Checkout from Hotel in Abu Dhabi</p>
          </div>
        </div>
        <div className={styles.location}>
          <h3>Sharjah- 2 Nights Stay</h3>
          <div className={styles.day}>
            <span>Day 2</span>
            <p>Check in to Bow View Lodge, 3 Star</p>
          </div>
          <div className={styles.day}>
            <span>Day 3</span>
            <p>Sharjah garden</p>
          </div>
          <div className={styles.day}>
            <span>Day 4</span>
            <p>Checkout from Hotel in Sharjah</p>
          </div>
        </div>
        <div className={styles.location}>
          <h3>Al Ain - 2 Nights Stay</h3>
          <div className={styles.day}>
            <span>Day 4</span>
            <p>Check in to Hotel in Al Ain</p>
          </div>
          <div className={styles.day}>
            <span>Day 5</span>
            <p>Al Ain Tech area</p>
          </div>
          <div className={styles.day}>
            <span>Day 6</span>
            <p>Checkout from Hotel in Al Ain</p>
          </div>
        </div>
      </div>
      <div className={styles.pricing}>
        <div className={styles.price}>
          <span className={styles.discountedPrice}>₹1,20,000/Adult</span>
          <span className={styles.originalPrice}>₹1,50,000</span>
          <span className={styles.discount}>24% OFF</span>
        </div>
        <button className={styles.paymentButton}>PROCEED TO PAYMENT</button>
        <div className={styles.coupons}>
          <h4>Coupons & Offers</h4>
          <div className={styles.coupon}>
            <p>No cost EMI @ ₹20,000</p>
            <span>Book your holidays with Easy EMI options.</span>
          </div>
          <div className={styles.applyCoupon}>
            <span>Have a Coupon Code?</span>
            <input type="text" placeholder="Enter Code" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourSummary;
