import React from 'react';
import styles from '../components/TourComponent.module.css';
import TourSummary from './TourSummary';

const TourComponent = () => {
  return (
    <>
    <div className={styles.tourContainer}>
      <h1>Dubai Tour (Planned by Induldge)</h1>
      <div className={styles.tourInfo}>
        <span>Friendly Package</span>
        <span>9N/10D</span>
        <span>1N Abu Dhabi</span>
        <span>2N Sharjah</span>
        <span>2N AI Ain</span>
      </div>
      <div className={styles.tourGallery}>
        <img src="https://hldak.mmtcdn.com/prod-s3-hld-hpcmsadmin/holidays/images/cities/355/Toronto.jpg" alt="Snow Explorer" />
        <img src="https://hldak.mmtcdn.com/prod-s3-hld-hpcmsadmin/holidays/images/cities/1940/nairobi-road.jpg" alt="Map" />
        <img src="https://hldak.mmtcdn.com/prod-s3-hld-hpcmsadmin/holidays/images/cities/390/Quebec%20City.jpg" alt="City View" />
        <img src="https://hldak.mmtcdn.com/prod-s3-hld-hpcmsadmin/holidays/images/cities/390/Quebec%20City.jpg" alt="Mountain Lake" />
        <img src="https://hldak.mmtcdn.com/prod-s3-hld-hpcmsadmin/holidays/images/cities/1940/nairobi-road.jpg" alt="Activities & Sightseeing" />
        <img src="https://hldak.mmtcdn.com/prod-s3-hld-hpcmsadmin/holidays/images/cities/1940/nairobi-road.jpg" alt="Property photos" />
      </div>
      <button className={styles.viewGallery}>VIEW GALLERY</button>
    </div>
    <TourSummary/>
    </>
  );
};

export default TourComponent;
