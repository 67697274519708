import './App.css';
import Home from './components/Home';
import PackageListing from "./components/PackageListing";
import ComingSoon from './components/ComingSoon';
import { Routes, Route } from 'react-router-dom';
import TourComponent from './components/TourComponent';

function App() {
  return (
    <>
    <div className="App">
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/packages" element={<PackageListing />} />
      <Route path="/tourDetails" element={<TourComponent />} />
      <Route path="/coming-soon" element={<ComingSoon />} />
    </Routes>
    </div>
    </>
  );
}

export default App;
