import React, { useRef } from 'react';
import styles from './Style.module.css';
import { Button, Box } from '@mui/material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Navbar from './Navbar';
import ComingSoon from './ComingSoon';
import { Link } from 'react-router-dom';

const Home = () => {
    const slideRef = useRef(null);

    const handleClickNext = () => {
        if (slideRef.current) {
            const firstItem = slideRef.current.firstElementChild;
            slideRef.current.appendChild(firstItem);
        }
    };

    const handleClickPrev = () => {
        if (slideRef.current) {
            const lastItem = slideRef.current.lastElementChild;
            slideRef.current.prepend(lastItem);
        }
    };

    return (
      <div className={styles.container}>
        <Navbar />
        <div ref={slideRef} className={styles.slide}>
          <div className={styles.item} style={{ backgroundImage: "url(https://i.ibb.co/Bq4Q0M8/img4.jpg)" }}>
            <div className={styles.content}>
              <div className={styles.name}>Singapore</div>
              <div className={styles.des}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, eum!</div>
              <Link to="/packages">
                <Button variant="contained" color="success">
                  Explore
                </Button>
              </Link>
            </div>
          </div>
          <div className={styles.item} style={{ backgroundImage: "url(https://i.ibb.co/jTQfmTq/img5.jpg)" }}>
            <div className={styles.content}>
              <div className={styles.name}>Dubai</div>
              <div className={styles.des}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, eum!</div>
              <Link to="/packages">
                <Button variant="contained" color="success">
                  Explore
                </Button>
              </Link>
            </div>
          </div>
          <div className={styles.item} style={{ backgroundImage: "url(https://i.ibb.co/NSwVv8D/img3.jpg)" }}>
            <div className={styles.content}>
              <div className={styles.name}>England</div>
              <div className={styles.des}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, eum!</div>
              <Button variant="contained" color="success">
                Explore
              </Button>
            </div>
          </div>
          <div className={styles.item} style={{ backgroundImage: "url(https://i.ibb.co/Bq4Q0M8/img4.jpg)" }}>
            <div className={styles.content}>
              <div className={styles.name}>Switzerland</div>
              <div className={styles.des}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, eum!</div>
              <Button variant="contained" color="success">
                Explore
              </Button>
            </div>
          </div>
          <div className={styles.item} style={{ backgroundImage: "url(https://i.ibb.co/jTQfmTq/img5.jpg)" }}>
            <div className={styles.content}>
              <div className={styles.name}>United States</div>
              <div className={styles.des}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, eum!</div>
              <Button variant="contained" color="success">
                Explore
              </Button>
            </div>
          </div>
          <div className={styles.item} style={{ backgroundImage: "url(https://i.ibb.co/qCkd9jS/img1.jpg)" }}>
            <div className={styles.content}>
              <div className={styles.name}>Sri Lanka</div>
              <div className={styles.des}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, eum!</div>
              <Button variant="contained" color="success">
                Explore
              </Button>
            </div>
          </div>
        </div>
        <Box className={styles.button}>
          <Button className="prev" onClick={handleClickPrev}>
            <ArrowCircleLeftIcon style={{ position: "absolute", width: "40px", height: "40px", color: "#fff" }} />
          </Button>
          <Button className="next" onClick={handleClickNext}>
            <ArrowCircleRightIcon style={{ position: "absolute", width: "40px", height: "40px", color: "#fff" }} />
          </Button>
        </Box>
        <Box sx={{ width: "100%", height: "100vh", position: "absolute", top: "100%" }}>
          {/* <PackageListing /> */}
          <ComingSoon />
        </Box>
      </div>
    );
};

export default Home;
