import { Typography, Box } from '@mui/material'
import React from 'react'

const ComingSoon = () => {
  return (
    <Box sx={{width: '100%', height: '100vh', background: 'black'}}>
    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', top: "30%", left: "30%", position: 'absolute'}}>
        <Typography sx={{fontSize: "100px", fontWeight: 700, fontFamily: 'cursive', color: 'white'}}>
            Coming Soon
        </Typography>
    </Box>
    </Box>
  )
}

export default ComingSoon
